/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import 'normalize.css';

import { black, original_bgcolor } from './style/colors';
import { Body } from './main/body';
import { Manual } from '../domain/entry-type';
import { ManualEntry } from './product-entry/manual-entry/manual-entry';
import { Header } from './banner/header';
import { text } from './style/defaults';
import { Footer } from './footer/footer';
import { QueryDisplayMessage } from './query-message/query-display-message';
import { desktop, notDesktop } from './common/media/media-type';
import { IntlProvider } from 'react-intl';
import { useAll } from './useAll';
import { messages } from './lang/messages';

const allElementStyle = [
  css`
    max-width: 1040px;
    margin: 0 auto;

    @media ${notDesktop} {
      width: 84%;
    }
  `,
];

export const All: FC = () => {
  const {
    activeEntryType,
    manualEntryValue,
    queryMessage,
    isLanguageTypeChinese,
    language,
  } = useAll();

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      key={language}
    >
      <div
        css={[
          css`
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            font-family: gt-america, sans-serif;
            color: ${black};
            background-color: ${original_bgcolor};
          `,
          text,
        ]}
      >
        <Header
          bodyStyle={allElementStyle}
          isLanguageTypeChinese={isLanguageTypeChinese}
        />
        <main
          css={[
            allElementStyle,
            css`
              margin-top: -72px;

              @media ${desktop} {
                margin-top: -90px;
              }
            `,
          ]}
        >
          {activeEntryType === Manual && (
            <ManualEntry value={manualEntryValue} />
          )}
          <div
            css={css`
              @media ${desktop} {
                margin-top: 35px;
              }
            `}
          >
            <QueryDisplayMessage message={queryMessage} />
          </div>
          <Body activeEntryType={activeEntryType} />
        </main>
        <Footer bodyStyle={allElementStyle} />
      </div>
    </IntlProvider>
  );
};
