import React, { FC, Fragment } from 'react';
import { useSendProductQuery } from './use-send-product-query';
import { useVariableInputUpdate } from './use-variable-input-update';
import { useSendManualQuery } from './use-send-manual-query';
import { useSendVariableInputUpdate } from './use-send-variable-input-update';

export const Effects: FC = ({ children }) => {
  useSendProductQuery();
  useSendManualQuery();
  useVariableInputUpdate();
  useSendVariableInputUpdate();

  return <Fragment>{children}</Fragment>;
};
