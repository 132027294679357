import { Reducer } from 'redux';
import { ProductQuerySent } from '../../main/product-query-sent';
import { ProductQuerySucceeded } from '../../main/product-query-success';
import { ProductQueryFailed } from '../../main/product-query-failed';
import { ManualQueryFailed } from '../../product-entry/manual-entry/manual-query-failed';
import { ManualQuerySent } from '../../product-entry/manual-entry/manual-query-sent';
import { ManualQuerySucceeded } from '../../product-entry/manual-entry/manual-query-success';
import { VariableInputUpdateSent } from '../../effects/variable-input-update-sent';
import { VariableInputUpdateSucceeded } from '../../effects/variable-input-update-succeeded';
import { VariableInputUpdateFailed } from '../../effects/variable-input-update-failed';

export type DetailQueryInFlightCount = number;

export type DetailQueryInFlightCountAction =
  | ProductQuerySent
  | ProductQuerySucceeded
  | ProductQueryFailed
  | ManualQuerySent
  | ManualQuerySucceeded
  | ManualQueryFailed
  | VariableInputUpdateSent
  | VariableInputUpdateSucceeded
  | VariableInputUpdateFailed;

export const initialDetailQueryInFlightCount = 0;

export const detailQueryInFlightCount: Reducer<
  DetailQueryInFlightCount,
  DetailQueryInFlightCountAction
> = (state = initialDetailQueryInFlightCount, action) => {
  switch (action.type) {
    case ProductQuerySent:
    case ManualQuerySent:
    case VariableInputUpdateSent:
      return state + 1;
    case ProductQuerySucceeded:
    case ManualQuerySucceeded:
    case VariableInputUpdateSucceeded:
    case ProductQueryFailed:
    case ManualQueryFailed:
    case VariableInputUpdateFailed:
      return state - 1;
    default:
      return state;
  }
};
