import { combineReducers } from 'redux';
import { EntryError, entryError as error } from './error';
import { ManualEntry, manualEntry as manual } from './manual';

export type Entry = {
  manual: ManualEntry;
  error: EntryError;
};

export const entry = combineReducers({
  manual,
  error,
});
