import { ProductQueryInput } from '../../../main/product-query-input';

import { ProductQuerySent } from '../../../main/product-query-sent';
import { QueryMetadata } from '../../../main/query-metadata';
import { ManualQuerySent } from '../../../product-entry/manual-entry/manual-query-sent';
import { ManualQueryInput } from '../../../product-entry/manual-entry/manual-query-input';
import { ProductQueryFailed } from '../../../main/product-query-failed';
import { ManualQueryFailed } from '../../../product-entry/manual-entry/manual-query-failed';
import { VariableInputUpdateSent } from '../../../effects/variable-input-update-sent';
import { VariableInputUpdateFailed } from '../../../effects/variable-input-update-failed';
import { TabSelected } from '../../../product-entry-type/change-tab';

export type LastSentQuery =
  | {
      type: 'product-id';
      values: ProductQueryInput;
      meta: QueryMetadata;
    }
  | {
      type: 'manual';
      values: ManualQueryInput;
      meta: QueryMetadata;
    }
  | null;

export const initialLastSentQuery = null;

export type LastSentQueryAction =
  | ProductQuerySent
  | ProductQueryFailed
  | ManualQuerySent
  | ManualQueryFailed
  | VariableInputUpdateSent
  | VariableInputUpdateFailed
  | TabSelected;

export const lastSentQuery = (
  state = initialLastSentQuery,
  action: LastSentQueryAction,
): LastSentQuery => {
  switch (action.type) {
    case ProductQuerySent:
      return {
        type: 'product-id',
        values: action.input,
        meta: action.meta,
      };
    case ManualQuerySent:
      return {
        type: 'manual',
        values: action.input,
        meta: action.meta,
      };
    case VariableInputUpdateSent:
      const {
        unitsPerOrder,
        monthlyUnitsStored,
        surge,
        hasBattery,
      } = action.input;
      return {
        type: action.query.type,
        values: {
          ...action.query.values,
          unitsPerOrder,
          monthlyUnitsStored,
          surge,
          hasBattery,
        },
        meta: {
          ...state.meta,
          update: true,
          error: null,
        },
      } as any;
    case VariableInputUpdateFailed:
    case ProductQueryFailed:
    case ManualQueryFailed:
      return {
        ...state,
        meta: {
          ...state.meta,
          error: action.error,
        },
      };
    case TabSelected:
      return initialLastSentQuery;
    default:
      return state;
  }
};
