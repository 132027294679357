/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import css from '@emotion/css/macro';

import { ProductEntryTypeTab } from './product-entry-type-tab';
import { EntryType, Manual } from '../../domain/entry-type';
import { FormattedMessage } from 'react-intl';
import { productEntryTypesMessages } from './product-entry-types-messages';

export type ProductEntryTypesProps = {
  activeEntryType: EntryType;
};

export const ProductEntryTypes: FC<ProductEntryTypesProps> = ({
  activeEntryType,
}) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        text-align: center;
      `}
    >
      <ProductEntryTypeTab
        css={css`
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        `}
        tab={Manual}
        active={activeEntryType === Manual}
      >
        <FormattedMessage {...productEntryTypesMessages.manualEntry} />
      </ProductEntryTypeTab>
    </div>
  );
};
