import { ServiceDetail as DomainServiceDetail } from '../../../domain/service-detail';
import { Reducer } from 'react';
import merge from 'lodash/fp/merge';
import { ProductQuerySucceeded } from '../../main/product-query-success';
import { ManualQuerySucceeded } from '../../product-entry/manual-entry/manual-query-success';
import { VariableInputUpdateSucceeded } from '../../effects/variable-input-update-succeeded';
import { TabSelected } from '../../product-entry-type/change-tab';
import { ProductQueryFailed } from '../../main/product-query-failed';
import { ManualQueryFailed } from '../../product-entry/manual-entry/manual-query-failed';

export type ServiceDetail = DomainServiceDetail | null;

export const initialServiceDetail: ServiceDetail = null;

export type ServiceDetailAction =
  | ProductQuerySucceeded
  | ManualQuerySucceeded
  | VariableInputUpdateSucceeded
  | ProductQueryFailed
  | ManualQueryFailed
  | TabSelected;

export const serviceDetail: Reducer<ServiceDetail, ServiceDetailAction> = (
  state = initialServiceDetail,
  action,
) => {
  switch (action.type) {
    case ProductQuerySucceeded:
    case ManualQuerySucceeded:
      return action.detail;
    case VariableInputUpdateSucceeded:
      return merge(state || {}, action.detail);
    case TabSelected:
    case ProductQueryFailed:
    case ManualQueryFailed:
      return initialServiceDetail;
    default:
      return state;
  }
};
